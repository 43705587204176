// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sketch-js": () => import("./../src/pages/sketch.js" /* webpackChunkName: "component---src-pages-sketch-js" */),
  "component---src-pages-works-edible-envelope-js": () => import("./../src/pages/works/edibleEnvelope.js" /* webpackChunkName: "component---src-pages-works-edible-envelope-js" */),
  "component---src-pages-works-hanged-man-js": () => import("./../src/pages/works/hangedMan.js" /* webpackChunkName: "component---src-pages-works-hanged-man-js" */),
  "component---src-pages-works-methods-and-processes-js": () => import("./../src/pages/works/methodsAndProcesses.js" /* webpackChunkName: "component---src-pages-works-methods-and-processes-js" */),
  "component---src-pages-works-re-fit-js": () => import("./../src/pages/works/re-fit.js" /* webpackChunkName: "component---src-pages-works-re-fit-js" */)
}

